import { Button, ButtonDesign } from 'components/Inputs/Button/Button'
import React, { FC, useEffect } from 'react'

type CalendlyButtonProps = {
  className?: string
  design?: ButtonDesign
  link?: string
}
export const CalendlyButton: FC<CalendlyButtonProps> = ({ className, design, children, link }) => {
  useEffect(() => {
    const head = document.querySelector('head')

    // Calendly styles
    const stylesheet = document.createElement('link')
    stylesheet.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css')
    stylesheet.setAttribute('rel', 'stylesheet')

    // Calendly script
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    script.setAttribute('type', 'text/javascript')

    if (head) {
      head.appendChild(script)
      head.appendChild(stylesheet)
    }
  }, [])

  return (
    <Button
      design={design}
      className={className}
      onClick={(event) => {
        event.preventDefault()

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Calendly.initPopupWidget({
          url: link || 'https://calendly.com/visita-libeen/contacto',
        })
        return false
      }}
    >
      {children}
    </Button>
  )
}
