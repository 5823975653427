import classNames from 'classnames'
import React, { ComponentPropsWithRef, FC } from 'react'
import { Icon, IconColor, IconName } from '../../DataDisplay/Icon/Icon'
import styles from './Button.module.scss'

export enum ButtonDesign {
  RED,
  BLUE,
  GREY,
  BURGUNDY,
  GREEN,
  YELLOW,
  WHITE,
  LIGHT_BLUE,
  OUTLINE_BLUE,
  OUTLINE_RED,
  TERTIARY,
}

export enum ButtonSize {
  BIG,
  SMALL,
  ICON,
}

type ButtonProps = {
  size?: ButtonSize
  design?: ButtonDesign
  fullWidth?: boolean
  autoWidth?: boolean
  iconName?: IconName
  iconColor?: IconColor
  direction?: 'left' | 'center' | 'right'
}

const buttonDesignToStyle: { [key in ButtonDesign]: string } = {
  [ButtonDesign.RED]: styles.red,
  [ButtonDesign.BLUE]: styles.blue,
  [ButtonDesign.GREY]: styles.grey,
  [ButtonDesign.BURGUNDY]: styles.burgundy,
  [ButtonDesign.GREEN]: styles.green,
  [ButtonDesign.YELLOW]: styles.yellow,
  [ButtonDesign.WHITE]: styles.white,
  [ButtonDesign.LIGHT_BLUE]: styles.lightBlue,
  [ButtonDesign.OUTLINE_BLUE]: styles.outlineBlue,
  [ButtonDesign.OUTLINE_RED]: styles.outlineRed,
  [ButtonDesign.TERTIARY]: styles.tertiary,
}

const buttonSizeToStyle: { [key in ButtonSize]: string } = {
  [ButtonSize.BIG]: styles.big,
  [ButtonSize.SMALL]: styles.small,
  [ButtonSize.ICON]: styles.icon,
}

/**
 *
 * @deprecated use Button from @components/Button instead.
 */
export const Button: FC<ComponentPropsWithRef<'button'> & ButtonProps> = ({
  design = ButtonDesign.BLUE,
  size = ButtonSize.BIG,
  iconColor = IconColor.WHITE,
  fullWidth = false,
  iconName,
  className,
  direction = 'center',
  ...props
}) => (
  <button
    type="button"
    className={classNames(
      className,
      styles.button,
      buttonSizeToStyle[size],
      buttonDesignToStyle[design],
      { [styles.fullwidth]: fullWidth },
      styles[`button--${direction}`]
    )}
    {...props}
  >
    {iconName && <Icon name={iconName} color={iconColor} size={24} style={{ letterSpacing: 0 }} />}
    {size !== ButtonSize.ICON && props.children}
  </button>
)
